.bg-nn-primary {
    background-color: $nn-primary;
}
.bg-nn-primary-lighter {
    background-color: $nn-primary-lighter;
}
.bg-nn-secondary{
    background-color: $nn-secondary;
}

