#nn-comment-submit {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 1.2rem;

    i {
        margin-left: -3px;
    }
}

.nn-post-wrapper, .nn-comment-wrapper {
    .nn-post-img-wrapper {
        display: flex;
        justify-content: center;

        img {
            max-height: 300px;
            width: auto;
        }
    }

    .nn-post-actions .post-action {
        height: 50px;
        width: 50px;
        background: $nn-primary;
        border-radius: 50%;
        color: #fff;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .25rem 0;
        cursor: pointer;
        transition: all ease-in-out 75ms;
        border: 1px solid transparent;
    }

    .nn-post-actions .post-action:hover {
        background: $nn-secondary-lighter;
        color: $cyan;
        border: 1px solid $nn-primary;
    }

    .nn-post-header-title {
        color: $nn-gray;
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .nn-post-header-subtitle {
        color: $nn-light-gray;
    }

    .nn-post-vote {
        padding: 0 .5rem;
        color: $nn-gray;

        i {
            cursor: pointer;
        }
    }

    .nn-post-admin-actions {
        padding: .5rem 1.25rem;
    }

}
