#nn-post-submit{
    width: 80px;
    height: 80px;
    font-size: 2.5rem;
    i {
        margin-left: -3px;
    }
}
.btn.nn-submit {
    border-radius: 50%;
    background: $nn-secondary-lighter;
    cursor: pointer;
    transition: all ease-in-out 75ms;
    border: 1px solid transparent;
    color: $nn-primary;
}
.btn.nn-submit:hover {
    background: $nn-secondary;
    color: $cyan;
    border: 1px solid $nn-secondary-lighter;
}
.nn-navbar-nav{
    .nav-item {
        margin-left: 1rem;
    }
}

#nn-login-button,  {
    font-weight: bold;
    background-color: $white;
    transition: all ease 200ms;
    border: 2px solid transparent;
}

#nn-logout-button,  #nn-profile-button{
    border: 2px solid $white;
}

#nn-profile-button i{
    padding: .1rem .5rem .1rem .1rem;
    color: $white;
    font-size: 1.3rem;
}

#nn-login-button:hover {
    color: $nn-primary;
    border-color: $cyan;
}

#nn-logout-button:hover {
    color: $white;
    border-color: $cyan;
}
