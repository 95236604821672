// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #ffffff;

$nn-primary: #e09d18;
$nn-primary-lighter: #e3a529;
$nn-secondary: #f0cd7c;
$nn-secondary-lighter: #fae9c8;
$nn-gray: #555555;
$nn-light-gray: #919191;
