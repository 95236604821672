#nn-footer {

    .nn-footer-content {

        line-height: 1.3;

        a {
            i {
                font-size: 2rem;
            }

            font-size: 1.2rem;
        }

        .nn-site-url {
            font-weight: bold;
            font-size: 2rem;
        }

        h3 {
            font-size: 1.8rem;
        }

        h4 {
            font-size: 1.2rem;
        }

        .nn-footer-links {
            a {
                font-size: .8rem;
                color: #ffffff;
            }
        }
    }
}
