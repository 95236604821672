.nn-comment-wrapper {
    .nn-comment-actions .comment-action:hover {
        background: $nn-secondary-lighter;
        color: $cyan;
        border: 1px solid $nn-primary;
    }

    .nn-comment-header-title {
        color: $nn-gray;
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .nn-comment-header-subtitle {
        color: $nn-light-gray;
    }

    .nn-comment-vote {
        padding: 0 .5rem;
        color: $nn-gray;
        i {
            cursor: pointer;
        }
    }

}
